<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 합동점검 기본정보 -->
          <c-card title="LBL0003089" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData('save')"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <c-select
                    required
                    :editable="editable && !popupParam.saiJointInspectionId"
                    codeGroupCd="JOINT_INSPECTION_TYPE_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="jointInspectionTypeCd"
                    label="합동점검종류"
                    v-model="data.jointInspectionTypeCd"
                    @datachange="datachange"
                  ></c-select>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 점검기간 -->
                <c-datepicker
                  required
                  :editable="editable"
                  label="점검일자"
                  name="data.jointStartDt"
                  v-model="data.jointStartDt">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 점검기간 -->
                <c-datepicker
                  :editable="editable"
                  label="점검일자2"
                  name="data.jointEndDt"
                  v-model="data.jointEndDt">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                  @datachange="datachange">
                </c-plant>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="LBL0003068"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 내부참석자 목록 -->
          <c-table
            ref="inGrid"
            title="점검자"
            :editable="editable"
            :columns="inGrid.columns"
            :gridHeightAuto="true"
            :data="data.innerList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            rowKey="userId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn 
                  v-if="editable" 
                  :showLoading="false" 
                  label="외부인력 추가" 
                  icon="add" 
                  @btnClicked="addOuterInner" />
                <!-- 추가 -->
                <c-btn 
                  v-if="editable" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addInner" />
                <!-- 제외 -->
                <c-btn 
                  v-if="editable && data.innerList && data.innerList.length > 0" 
                  :showLoading="false"  
                  label="LBLEXCEPT" 
                  icon="remove" 
                  @btnClicked="removeInner" />
              </q-btn-group>
            </template>
          </c-table>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 참가 협력업체 -->
          <c-table
            ref="vendorGrid"
            title="업체"
            :editable="editable"
            :columns="vendorGrid.columns"
            :data="data.vendorList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            rowKey="vendorCd"
            selection="multiple"
            :gridHeightAuto="true"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 추가 -->
                <c-btn 
                  v-if="editable" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addVendor" />
                <!-- 제외 -->
                <c-btn 
                  v-if="editable && data.vendorList && data.vendorList.length > 0" 
                  :showLoading="false"  
                  label="LBLEXCEPT" 
                  icon="remove" 
                  @btnClicked="removeVendor" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 점검항목 -->
          <c-table
            ref="itemGrid"
            title="점검항목(점검표 요약)"
            :editable="editable"
            :columns="itemGrid.columns"
            :data="checklistResult('JIKC000001')"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :gridHeightAuto="true"
            :merge="itemGrid.merge"
          >
            <!-- rowKey="jointInspectionResultId"
            selection="multiple" -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="editable"
                  :isImmShow="true"
                  :requestContentsCols="requestContentsCols"
                  tableKey="jointInspectionResultId"
                  ibmTaskTypeCd="ITT0000165"
                  ibmTaskUnderTypeCd="ITTU000180"
                  @imprChange="imprChange"
                />
              </template>
            </template>
            <!-- <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addResult"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    this.data.itemResultList.length > 0
                  "
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeResult"
                />
              </q-btn-group>
            </template> -->
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 점검항목 -->
          <c-table
            ref="itemGrid2"
            title="평가항목"
            :editable="editable"
            :columns="itemGrid.columns"
            :data="checklistResult('JIKC000002')"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :gridHeightAuto="true"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-md">
          <!-- 합동점검 관련 첨부파일 -->
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="LBL0003093">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
      <q-dialog v-model="prompt" persistent>
        <q-card style="min-width: 350px">
          <q-card-section>
            <div class="text-h6">업체명</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <q-input dense v-model="hiddenDeptName" />
          </q-card-section>
          <q-card-section>
            <div class="text-h6">이름</div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <q-input dense v-model="hiddenUserName" />
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn flat :label="$comm.getLangLabel('LBLCANCEL')" @click="cancelOuter" />
            <q-btn flat :label="$comm.getLangLabel('LBLADD')" @click="setOuter" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dataCheckInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiJointInspectionId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SOP_CONSOLIDATION_CHECK',
        taskKey: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  computed: {
    disabled() {
      return this.popupParam.saiJointInspectionId ? false : true;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['jointItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
    isOld() {
      return Boolean(this.popupParam.saiJointInspectionId)
    },
  },
  watch: {
    'count'() {
      this.getDetail();
    },
  },
  data() {
    return {
      prompt: false,
      gridHeight: '200px',
      gridHeight2: '350px',
      inGrid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '부서/업체명',
            align: 'center',
            style: 'width: 80px',
          },
          {
            name: 'userName',
            field: 'userName',
            // 이름
            label: 'LBLNAME',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: 'LBLPOSITION',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
      },
      outGrid: {
        columns: [
          {
            name: 'company',
            field: 'company',
            // 소속
            label: 'LBL0003074',
            type: 'text',
            align: 'center',
            style: 'width: 80px',
            required: true,
          },
          {
            name: 'userName',
            field: 'userName',
            // 이름
            label: 'LBLNAME',
            style: 'width: 80px',
            type: 'text',
            required: true,
            align: 'center',
          },
          {
            name: 'spotName',
            field: 'spotName',
            // 직책
            label: 'LBLPOSITION',
            type: 'text',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
      },
      vendorGrid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            // 협력업체
            label: 'LBL0003073',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            type: 'textarea',
            align: 'left',
            style: 'width: 60%',
          },
        ],
      },
      itemGrid:  {
        merge: [
          { index: 0, colName: 'jointItemType' },
        ],
        columns: [],
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'POST',
      isApproval: false,
      deleteUrl: '',
      deleteInUrl: '',
      deleteOutUrl: '',
      deleteVendorUrl: '',
      deleteResultUrl: '',
      hiddenDeptName: '',
      hiddenUserName: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      data: {
        saiJointInspectionId: '',  // 합동점검 일련번호
        plantCd: '',  // 사업장 코드
        jointInspectionTypeCd: null,
        jointInspectionName: '',  // 합동점검명
        jointStartDt: '',  // 점검시작일
        jointEndDt: '',  // 점검종료일
        jointDts: [],
        remark: '',  // 내용
        jointCompleteFlag: 'N',  // 완료여부
        innerList: [],
        outerList: [],
        vendorList: [],
        itemResultList: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sai.joint.get.url;
      this.saveUrl = transactionConfig.sai.joint.insert.url;
      this.deleteUrl = transactionConfig.sai.joint.delete.url;
      this.deleteInUrl = transactionConfig.sai.joint.delete.inner;
      this.deleteOutUrl = transactionConfig.sai.joint.delete.outer;
      this.deleteVendorUrl = transactionConfig.sai.joint.delete.vendor;
      this.deleteResultUrl = transactionConfig.sai.joint.delete.result;
      this.setHeader();
      this.getDetail();
    },
    addOuterInner() {
      this.prompt = true;
    },
    cancelOuter() {
      this.hiddenDeptName = '';
      this.hiddenUserName = '';
      this.prompt = false;
    },
    setOuter() {
      this.prompt = false;
      this.data.innerList.push({
        saiJointInspectionId: this.popupParam.saiJointInspectionId,  // 합동점검 일련번호
        userId: this.hiddenUserName,  // 사용자 아이디
        userName: this.hiddenUserName,
        deptName: this.hiddenDeptName,
        jobName: '',
        remark: '',  // 비고
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
      this.hiddenDeptName = '';
      this.hiddenUserName = '';
    },
    checklistResult(_val) {
      return this.$_.filter(this.data.itemResultList, { jointItemKindCd: _val });
    },
    setHeader() {
      this.$comm.getComboItems('JOINT_RESULT_CD').then(_result => {
        this.itemGrid.columns = [
          {
            name: 'jointItemType',
            field: 'jointItemType',
            // 구분
            label: 'LBL0003094',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'jointItemName',
            field: 'jointItemName',
            // 점검항목
            label: 'LBL0003094',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'jointResultCd',
            field: 'jointResultCd',
            // 점검결과
            label: 'LBL0003095',
            setHeader: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            setHeader: true,
            name: 'jointContents',
            field: 'jointContents',
            // 비고
            label: 'LBLREMARK',
            align: 'left',
            type: 'textarea',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선/진행상태
            label: 'LBL0003096',
            align: 'center',
            style: 'width:400px',
            type: 'custom',
            sortable: false
          },
        ]
      });
    },
    getDetail() {
      if (this.popupParam.saiJointInspectionId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {saiJointInspectionId: this.popupParam.saiJointInspectionId};
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', _result.data.saiJointInspectionId)
        },);
      }
    },
    getOriItemList(_val) {
      this.data.itemResultList = [];
      this.$http.url = selectConfig.sai.joint.items.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.data.plantCd,
        plantCdList: [this.data.plantCd],
        useFlag: 'Y',
        jointInspectionTypeCd: _val,
      };
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.data.itemResultList.push({
            saiJointInspectionId: this.popupParam.saiJointInspectionId,
            jointInspectionResultId: uid(),
            jointInspectionTypeCd: _item.jointInspectionTypeCd,
            jointItemKindCd: _item.jointItemKindCd,
            jointItemType: _item.jointItemType,
            jointItemName: _item.jointItemName,
            jointResultCd: 'JRC0000001',
            jointContents: '',
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C',
          }) 
        })
      },);
    },
    datachange(data) {
      if(!data.value) {
        return;
      } else {
        this.getOriItemList(data.value);
      }
    },
    addInner() {
      this.popupOptions.title = "LBL0003075"; // 내부참석자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.data.attends, { userId: _item.userId }) === -1) {
            this.data.innerList.push({
              saiJointInspectionId: this.popupParam.saiJointInspectionId,  // 합동점검 일련번호
              userId: _item.userId,  // 사용자 아이디
              userName: _item.userName,
              deptName: _item.deptName,
              jobName: _item.jobName,
              remark: '',  // 비고
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    addOuter() {
      if (!this.data.outerList) this.data.outerList = [];
      this.data.outerList.push({
        saiJointInspectionId: this.popupParam.saiJointInspectionId,  // 합동점검 일련번호
        saiJointOuterUserId: uid(),  // 합동점검 외부참석자 일련번호
        company: '',  // 소속
        userName: '',  // 이름
        jobName: '',  // 직책
        remark: '',  // 비고
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeInner() {
      let selectData = this.$refs['inGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteInUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.innerList = this.$_.reject(this.data.innerList, item);
              })
              this.$refs['inGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeOuter() {
      let selectData = this.$refs['outGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteOutUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.outerList = this.$_.reject(this.data.outerList, item);
              })
              this.$refs['outGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addVendor() {
      this.popupOptions.title = "협력업체 검색"; // 협력업체 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeVendorPopup;
    },
    closeVendorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.data.vendorList, { vendorCd: _item.vendorCd, }) === -1) {
            this.data.vendorList.push({
              saiJointInspectionId: this.popupParam.saiJointInspectionId,
              vendorCd: _item.vendorCd,
              vendorName: _item.vendorName,
              remark: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeVendor() {
      let selectData = this.$refs['vendorGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteVendorUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.vendorList = this.$_.reject(this.data.vendorList, item);
              })
              this.$refs['vendorGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData(flag) {
      if (this.popupParam.saiJointInspectionId) {
        this.saveUrl = transactionConfig.sai.joint.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.joint.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = 'MSGSAVE';
          if (flag === 'appr') {
            message = 'SAVEAPPROVE'; // 결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?
          }
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message, // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.data.innerList = this.data.innerList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.data.outerList = this.data.outerList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.data.vendorList = this.data.vendorList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              if (flag === 'save') {
                this.isSave = !this.isSave;
              } else {
                this.isApproval = !this.isApproval;
              }
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'saiJointInspectionId', result.data)
      this.$set(this.attachInfo, 'taskKey', this.popupParam.saiJointInspectionId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiJointInspectionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addResult() {
      this.data.itemResultList.push({
        saiJointInspectionId: this.popupParam.saiJointInspectionId,
        jointInspectionResultId: uid(),
        jointItemName: '',
        jointResultCd: 'JRC0000001',
        jointContents: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeResult() {
      let selectData = this.$refs['itemGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteResultUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.itemResultList = this.$_.reject(this.data.itemResultList, item);
              })
              this.$refs['itemGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['itemGrid'].$refs['compo-table'].resetVirtualScroll();
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>
